import React, {useContext, useEffect} from "react";
import { useRecentQuery } from "../../hooks/useRecentQuery";
import { useWoningenQuery } from "../../hooks/useWoningenQuery";
import Card from "../Card/Card";
import Carousel from "../Carousel/Carousel";
import './recents.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faHome } from '@fortawesome/free-solid-svg-icons'
import VestigingContext from "../../store/vestiging-context";
import { useSettingsQuery } from "../../hooks/useSettingsQuery";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span className="p-3 pb-2 cursor-pointer bg-blue transition duration-300 hover:bg-red text-light absolute -top-5 right-unset lg:right-4 z-5" style={{zIndex: 5}} onClick={onClick}><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></span>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span className="p-3 pb-2 cursor-pointer bg-blue transition duration-300 hover:bg-red text-light absolute -top-5 right-unset lg:right-16 z-5" style={{zIndex: 5}} onClick={onClick}><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon></span>
  );
}

const Recents = (props) => {
    const settings = useSettingsQuery()
    const data = useWoningenQuery()
    const selectedVestiging = useContext(VestigingContext);
    const vestiging = props.vestiging;
    const seller = data.allWpVerkopendeMakelaar
    let filtered;
    
    if(vestiging !== undefined && vestiging !== null){
      filtered = data.allWpWoning.nodes.filter((e) => { 
        if (e.hqVestiging === vestiging){ 
            return true;
        }else {
            return false;
        }
    })
    } else{
      filtered = data.allWpWoning.nodes.filter((e) => { 
        if (selectedVestiging.vestiging !== undefined && selectedVestiging.vestiging !== 'undefined' && selectedVestiging.vestiging !== '' && e.hqVestiging === selectedVestiging.vestiging){ 
            return true;
        }else if(selectedVestiging.vestiging !== undefined && selectedVestiging.vestiging !== '' && selectedVestiging.vestiging !== 'undefined'){
            return false;
        }else{ 
            return true;
        }
    })
    }
   

    // useEffect(()=>{
    //   let e = document.querySelectorAll('.slick-slide').style.height = '100px';
    //   let h = document.getElementsByClassName('slick-track').clientHeight
    //   // e.style.height =  h + 'px'
    // })
    
    const filterSold = filtered.filter((e) => {
      if(e.hqStatus !== 'Verkocht' && e.hqStatus !== 'Ingetrokken'){ return true }
      else { return false }
    }).slice(0, 8)
    const woningen = filterSold.map((woning) => (
      <Card styles="mr-0 lg:mr-5 mt-8 mb-8 h-full" seller={seller} key={woning.id} data={woning} ></Card>
    ))
    const icon = <FontAwesomeIcon icon={faHome} className="text-blue" size="2x" />

    return(
       <div className=""> 
            <div className="w-full lg:w-11/12 ml-auto">
                <div className={`w-full ${woningen.length > 0 ? `lg:mt-24 mt-8` : ``} px-8 lg:px-0`}>
                  {woningen.length > 0 ? 
                  
                    <h2 className="lg:text-4xl text-2xl text-blue">{settings.allWp.edges[0].node.acfOptionsHomepagina.homepageField.homepaginaRecentAanbodTitel}</h2>
                    
                    : null }
                       <Carousel slides={woningen} 
                          settings={{
                            centerMode: false, 
                            infinite: false, 
                            arrows: true,
                            nextArrow: <SampleNextArrow />,
                            prevArrow: <SamplePrevArrow />, 
                            dots: false, 
                            rtl: false, 
                            slidesToShow: 4.5,
                            slidesToScroll: 1,  
                            responsive: [
                              {
                                breakpoint: 1536,
                                settings: {
                                  slidesToShow: 3.5,
                                  slidesToScroll: 3.5,
                                  infinite: false,
                                  dots: false
                                }
                              },
                                {
                                  breakpoint: 1024,
                                  settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3,
                                    infinite: true,
                                    dots: false
                                  }
                                },
                                {
                                  breakpoint: 800,
                                  settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                    gap: '24px'
                                  }
                                },
                                {
                                  breakpoint: 600,
                                  settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                  }
                                }
                              ]
                            }}> 
                        </Carousel>
                </div>
            </div>
       </div> 
    )
}

export default Recents;