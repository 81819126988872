import React from "react";
import Container from "../../Container/Container";
import BlogGrid from "../../Blog/BlogGrid";
import CtaButton from "../../CtaButton/cta";
import { faArrowRight, faHome } from "@fortawesome/free-solid-svg-icons"

export default function CROBlogs({data}){
    return(
        <div>
            <Container styles="relative w-full max-w-none lg:mt-6 mb-16 lg:px-0 px-5">
                <h2 className="text-blue w-full lg:w-1/3 text-4xl">
                Bekijk de laatste ontwikkelingen
                </h2>
                <p className="text-blue opacity-40 mb-4">
                {" "}
                Lees onze inzichten over de huidige woningmarkt.
                </p>
                <div className="inline-block mb-16">
                <CtaButton
                    target={`/blog`}
                    title="Bekijk alle blogs"
                    icon={faArrowRight}
                ></CtaButton>
                </div>
                <BlogGrid limit={true}></BlogGrid>
            </Container>
            
        </div>
    )
}