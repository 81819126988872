import React from "react";
import CROHero from "./Hero/hero";
import CROLinks from "./Links/links";
import CROContent from "./ContentWithImage/content";
import Recents from "../Recents/Recents";
import CROUitgelichteLinks from "./HighlightedLinks/uitgelichteLinks";
import CROCtaBanner from "./CTABanner/ctabanner";
import Testimonial from "../Testimonials/Testimonials";
import CROBlogs from "./Blogs/blogs";
import Container from "../Container/Container";

export default function Repeater({data}){

    const blocks = data

    return(
        <div className="renderer">
            {
                blocks?.map((block) => {       
                        // Verkrijgt de groepsnaam van het blok om het type blok te bepalen.
                        const groupName = block.fieldGroupName?.substring(block.fieldGroupName.indexOf("Landingspagina_"));
                        console.log(groupName)
                        switch (groupName) {
                            case 'Landingspagina_Landingspagina_Blokken_Hero':
                                return <CROHero data={block} />
                            case 'Landingspagina_Landingspagina_Blokken_Links':
                                    return <CROLinks data={block} />
                            case 'Landingspagina_Landingspagina_Blokken_InhoudMetAfbeelding':
                                return <CROContent data={block} />
                            case 'Landingspagina_Landingspagina_Blokken_Woningaanbod':
                                return <div className="cro-recents"><Recents /></div>
                            case 'Landingspagina_Landingspagina_Blokken_UitgelichteLinks':
                                return <CROUitgelichteLinks data={block} />
                            case 'Landingspagina_Landingspagina_Blokken_CtaBanner':
                                return <CROCtaBanner data={block} />
                            case 'Landingspagina_Landingspagina_Blokken_Reviews':
                                return <Container><Testimonial /></Container>
                            case 'Landingspagina_Landingspagina_Blokken_Blogs':
                                return <CROBlogs />
                        }
                })
            }
        </div>
    )
}