import React from "react";
import Container from "../../Container/Container";
import { Link } from "gatsby";

export default function CROCtaBanner({data}){
    return(
        <div className="cro-banner" style={{backgroundImage: `url('${data.achtergrond.mediaItemUrl}')`}}>
            <Container>
                <div className="content">
                    <h2>{data.titel}</h2>
                    <div className="tekst" dangerouslySetInnerHTML={{__html: data.tekst}}></div>
                    <Link className="button" to={data.knop.link}><span className="tekst">{data.knop.tekst}</span></Link>
                </div>
            </Container>
        </div>
    )
}