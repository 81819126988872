import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby"
import SmallHero from "../components/SmallHero/SmallHero"
import G from "../images/groenhout_g.png"
import Seo from "../components/Seo/Seo"
import ContentImageLeft from "../components/ContentImageLeft/ContentImageLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import Container from "../components/Container/Container"
import Recents from "../components/Recents/Recents"
import Repeater from "../components/_RepeaterBlocks/Repeater";


const Post = ({data}) => {

  console.log(data)

  const [schema, setSchema] = useState()
  let qa = []
 

  useEffect(()=>{
      const questions = Array.from(
        document.getElementsByClassName('question')
      );
      const answers = Array.from( 
        document.getElementsByClassName('answer')
      );
      let q = []
      let a = []
      questions.forEach(question => q.push(question.innerText))
      answers.forEach(answer => a.push(answer.innerText))

      let merge = q.reduce((obj ,key, index) => ({...obj, [key]: a[index]}), {})

      Object.entries(merge).forEach(([key, value])=>{
        let item = {"@type": "Question", "name": key, "acceptedAnswer": { "@type": "Answer", "text": value } }
        qa.push(item)
      })
      setSchema(`{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": ${JSON.stringify(qa)}
        }`)
      
  },[])

  if(!data) return null
  
  const image  = data.wpLandingspagina.featuredImage !== null ? data.wpLandingspagina.featuredImage.node.mediaItemUrl : ``
  const img = data.wpLandingspagina.landingspagina.afbeelding?.mediaItemUrl;
  const usps = data.wpLandingspagina.landingspagina.usps !== null ? data.wpLandingspagina.landingspagina.usps.map((e)=> e.usp) : null
  const cta = data.wpLandingspagina.landingspagina ? <Link to={data.wpLandingspagina.landingspagina.ctaTarget} className="inline-block"><span className="text-white bg-red w-auto inline py-4 px-8 hover:pr-5 mt-4 flex flex-row justify-between items-center fullBtn"><span className="buttonHoverWhite text-center">{data.wpLandingspagina.landingspagina.ctaTekst}</span></span></Link> : null
  const vestiging = data.wpLandingspagina.landingspagina.vestigingItem ? data.wpLandingspagina.landingspagina.vestigingItem.title : null


  
  return (
    <>
      <Seo title={data.wpLandingspagina.seo.title} description={data.wpLandingspagina.seo.metaDesc}></Seo>
      {
          schema !== undefined ?
        <script type="application/ld+json">
          {schema}
	      </script>
        : null
        }
      {
        data.wpLandingspagina.landingspagina.blokken?.length == 0 ?
      <>
        <SmallHero title={data.wpLandingspagina.title} overlay={true} overlayImage={G} image={image !== undefined ? image : ''}></SmallHero>
        <Container styles={`px-5 xl:w-8/12 lg:8/12 md:w-10/12 my-16`}>
            <ContentImageLeft styles={`my-16`} contain={false} icon={<FontAwesomeIcon icon={faHome}></FontAwesomeIcon>} title={data.wpLandingspagina.landingspagina.subtitle} sub={data.wpLandingspagina.landingspagina.subtekst} usp={usps} vestiging={false} cta={cta} image={img}></ContentImageLeft>
        </Container>
        {data.wpLandingspagina.landingspagina.vestiging ?
        <Recents vestiging={vestiging}></Recents>
        :
        null
        }
        <Container styles={`px-5 xl:w-6/12 lg:8/12 md:w-10/12 my-16`}>
            <div className="post" dangerouslySetInnerHTML={{__html: data.wpLandingspagina.content}}></div>
        </Container>
      </>
      : 
        <Repeater data={data.wpLandingspagina.landingspagina.blokken} />
      }

    </>

  )
}

export default Post;

export const landingsQuery = graphql`
query($id: Int!){
    wpLandingspagina(databaseId: {eq: $id}){
      title
      seo{
        metaDesc
        title
      }
      content
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      landingspagina{
          cta
          ctaTarget
          ctaTekst
          recenteWoningen
          subtekst
          subtitle
          afbeelding{
            mediaItemUrl
          }
          usps{
            usp
          }
          vestiging
          vestigingItem {
            ... on WpVestiging {
              title
            }
        }
        blokken {
          ... on WpLandingspagina_Landingspagina_Blokken_Hero {
              beschrijving
              fieldGroupName
              titel
              googleRating
              achtergrond {
                mediaItemUrl
              }
              knop {
                tekst
                link
              }
            }
            ... on WpLandingspagina_Landingspagina_Blokken_Links {
              fieldGroupName
              links {
                titel
                tekst
                link
                afbeelding {
                  mediaItemUrl
                }
              }
            }
            ... on WpLandingspagina_Landingspagina_Blokken_InhoudMetAfbeelding {
              fieldGroupName
              omgekeerd
              columnleft {
                titel
                tekst
                knop {
                  tekst
                  link
                }
              }
              columnright {
                afbeelding {
                  mediaItemUrl
                }
              }
            }
            ... on WpLandingspagina_Landingspagina_Blokken_Woningaanbod {
              fieldGroupName
            }
            ... on WpLandingspagina_Landingspagina_Blokken_Reviews {
              fieldGroupName
            }
            ... on WpLandingspagina_Landingspagina_Blokken_Blogs {
              fieldGroupName
            }
            ... on WpLandingspagina_Landingspagina_Blokken_UitgelichteLinks {
              fieldGroupName
              tekst
              titel
              knop {
                tekst
                link
              }
              links {
                titel
                tekst
                link
                afbeelding {
                  mediaItemUrl
                }
              }
            }
            ... on WpLandingspagina_Landingspagina_Blokken_CtaBanner {
              fieldGroupName
              tekst
              titel
              knop {
                tekst
                link
              }
              achtergrond {
                mediaItemUrl
              }
            }
        }
      }
    }
  }
  `