import React from "react";
import Container from "../../Container/Container";
import { Link } from "gatsby";
import CtaButton from "../../CtaButton/cta";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CROContent({data}){
    return(
        <div className={`cro-content ${data.omgekeerd ? 'reverse' : ''}`}>
            <Container>
                <div className="left">
                    <h2>{data.columnleft.titel}</h2>
                    <div dangerouslySetInnerHTML={{__html: data.columnleft.tekst}}></div>
                    <Link className="cro-button button" to={data.columnleft.knop.link}><span className="cro-tekst">{data.columnleft.knop.tekst}</span><div className="cro-icon"><FontAwesomeIcon icon={faArrowRight} /></div></Link>
                </div>
                <div className="right">
                    <img src={data.columnright.afbeelding.mediaItemUrl} />
                </div>
            </Container>
        </div>
    )
}