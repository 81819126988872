import React from "react";
import Container from "../../Container/Container";
import CROLinks from "../Links/links";
import { Link } from "gatsby";
import CtaButton from "../../CtaButton/cta";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CROUitgelichteLinks({data}){
    return(
        <div className="cro-uitgelicht">
            <div className="background">
            <Container>
                <div className="content">
                    <h2>{data.titel}</h2>
                    <p>{data.tekst}</p>
                </div>
                <CROLinks data={data} />
                <Link className="cro-button button" to={data.knop.link}><span className="cro-tekst">{data.knop.tekst}</span><div className="cro-icon"><FontAwesomeIcon icon={faArrowRight} /></div></Link> 
                
            </Container>
            </div>
        </div>
    )
}