import { graphql, useStaticQuery } from "gatsby";
import React, {useContext} from "react";
import VestigingContext from "../../store/vestiging-context";
import BlogCard from "./BlogCard";

const BlogGrid = (props) => {

    const data = useStaticQuery(graphql`
        query BlogQuery {
            allWpPost(sort: {order: DESC, fields: date}) {
                edges {
                  node {
                    date
                    title
                    uri
                    isSticky
                    categories {
                      nodes {
                        name
                      }
                    }
                    featuredImage {
                      node {
                        altText
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
    `)

    const posts = props.limit ? data.allWpPost.edges.filter((n)=> n.node.isSticky ).map((e, i) => {
        return(
          <BlogCard data={e.node}></BlogCard>
        )
    }) : data.allWpPost.edges.map((e, i) => {
      return(
        <BlogCard data={e.node}></BlogCard>
      )
  })

    return(
        <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8 w-full p-0 m-0">
            {posts}
        </div>
    )
}

export default BlogGrid;