import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { useReviewQuery } from "../../hooks/useReviewQuery";
import VestigingContext from "../../store/vestiging-context";
import Carousel from "../Carousel/Carousel";
import Container from "../Container/Container";
import TestimonialCard from "./TestimonialCard/TestimonialCard"

const Testimonial = (props) => {

    const data = useReviewQuery()
    const reviews = data.allWpReview.edges

    const icon = <FontAwesomeIcon icon={faCommentAlt} className="text-blue" size="2x" /> 

    const ctx = useContext(VestigingContext);
    const title = ctx.vestiging !== undefined && ctx.vestiging !== 'undefined' ? <span>Wat vinden onze klanten van <br /><span className="text-red">Groenhout {ctx.vestiging}</span></span> : ctx.vestiging == undefined && props.vestiging !== undefined ? <span>Wat onze klanten zeggen over <br /><span className="text-red">Groenhout {props.vestiging === 'Assen' ? 'Makelaars' : props.vestiging}</span></span> : `Wat onze klanten over ons zeggen`
    const showReviews = ctx.vestiging !== undefined && ctx.vestiging !== 'undefined' ? reviews.filter((e) => {
      if (e.node.categories.nodes.some(e => e.name === ctx.vestiging)) {
          return true;
      }else{
          return false
      }
  }) : ctx.vestiging == undefined && props.vestiging !== undefined ? reviews.filter((e) => {
    if (e.node.categories.nodes.some(e => e.name === props.vestiging)) {
        return true;
    }else{
        return false
    }
  }) : reviews

  const reviewContent = showReviews.map((e) => (
    <TestimonialCard key={e} review={e.node.content} reviewee={e.node.title} categories={e.node.categories.nodes} number={e.node.reviews?.cijfer} vestiging={ctx.vestiging !== undefined && ctx.vestiging !== 'undefined' ? ctx.vestiging : props.vestiging}></TestimonialCard>
))

const infinite = reviewContent.length > 3 ? true : false


    return(
        <>
            <Container styles={` ${reviewContent.length > 0 ? 'mt-8 lg:mt-16' : null} px-8`}>
              { 
                reviewContent.length > 0 ?
                  <h2 className="text-center text-4xl text-blue w-full lg:w-1/2 mx-auto mb-0">{title}</h2>
                : null
              }
            </Container>
            <Carousel slides={reviewContent} class="testimonials" 
                settings={{
                    centerMode: false, 
                    arrows: false,  
                    dots: false,  
                    slidesToShow: 3, 
                    slidesToScroll: 1, 
                    speed: 500,
                    swipeToSlide: true,
                    touchThreshold: 10,
                    easing: 'ease-out',
                    infinite: infinite, 
                    autoplay: true, 
                    autoplaySpeed: 5000,
                    responsive: [
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: false
                          }
                        },
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 1.5,
                            slidesToScroll: 1.5
                          }
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                          }
                        }
                      ]
                    }}>
                </Carousel>
        </>
    )
}

export default Testimonial;