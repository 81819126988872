import React from "react";
import Container from "../../Container/Container";
import { Link } from "gatsby";

export default function CROLinks({data}){

    const arrow = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.62 4.45331L13.6667 8.49998L9.62 12.5466" stroke="#D61B27" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.33333 8.49994H13.5533" stroke="#D61B27" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    return(
        <div className="cro-links">
            <Container>
                <div className="link-grid" style={{'grid-template-columns': `repeat(${data.links.length}, minmax(0, 1fr))`}}>
                    {data.links.map((link)=>{
                    return(
                            <Link to={link.link} className="link">
                                <div className="img-container"><img src={link.afbeelding.mediaItemUrl} /></div>
                                <h3>{link.titel} {arrow}</h3>
                                <div className="tekst" >{link.tekst}</div>
                            </Link>
                        )
                    })}
                </div>
            </Container>
        </div>
    )
}