import React, { useContext, useState } from "react";
import VestigingContext from "../../../store/vestiging-context";
import G from "../../../images/groenhout_g.png"

const TestimonialCard = (props) => {

    const content = props.review
    const title = props.reviewee
    const categories = props.categories
    const vestiging = props.vestiging

    const [expand, setExpand] = useState(false)

    return(
        <div className="my-16 lg:mx-12 mx-4 bg-light p-10 testimonial-card relative">
            <span className="bg-red text-light p-5 absolute left-0 top-0 font-bold w-full">Groenhout {vestiging != null ? vestiging : categories[0].name}</span>
            <span className="font-bold text-light flex items-center justify-center absolute top-0 right-0 bg-blue p-5">{props.number ? props.number : '9.0'}</span>
            <div dangerouslySetInnerHTML={{__html: expand ? content : content.split(" ").splice(0, 20).join(" ") + '...'}} className="mt-12"></div>
            <div className="text-red cursor-pointer mb-5 -mt-5" onClick={() => {setExpand(!expand)}}>{expand ? `Minder` : `Lees verder`}</div>
            <span>{title}</span>
            <img src={G} alt="G-logo" className="absolute -bottom-16 m-0 -right-16 h-36" />
        </div>
    )
}

export default TestimonialCard;